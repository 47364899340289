<template>
  <div class="overflow-auto">
    <pro-document-breadcrumbs
      :breadcrumbs="[
        {
          name: 'menu.ProcurerManagement',
          icon: 'PROSmart-AccountEdit',
          to: { name: 'ProcurerManagementList' },
        },
        { name: 'menu.AddProcurerInfo' },
      ]"
      user-role="procurer"
    ></pro-document-breadcrumbs>

    <div class="q-pa-md">
      <pro-form-render
        mode="Edit"
        code="P_AddProcurerInfo"
        :form-data-promise="formDataPromise()"
        @pro-form-submit="
          () => this.$router.push({ name: 'ProcurerManagementList' })
        "
      />
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender.vue";

export default {
  name: "AddProcurerInfo",
  components: { ProFormRender, ProDocumentBreadcrumbs },
  data() {
    return {};
  },
  methods: {
    formDataPromise() {
      return new Promise((resolve) => {
        // let params = this.$route.params;
        //
        // let groups = [];
        //
        // for (let i = 0; i < params.groups.length; i++) {
        //   groups.push(params.groups[i].code);
        // }
        //
        // let obj = {
        //   id: params.hcmUserId,
        //   code: params.loginId,
        //   eName: params.eName,
        //   tName: params.tName,
        //   cName: params.cName,
        //   departmentStr: !params.departmentStr ? [] : params.departmentStr,
        //   groups: groups,
        //   email: {
        //     emails: [{ name: "", email: params.email }],
        //     isValid: params.email.length > 0,
        //   },
        //   ssoId: params.ssoId,
        //   ssoType: params.ssoType,
        //   enable: params.enable,
        // };

        resolve({ enable: "true", changePassword: "true" });
      });
    },
  },
};
</script>
